// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'name',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'icon',
    label: '',
    img: true,
    width: '120'
  }, {
    prop: 'wechat_id',
    Object: 'value',
    label: '',
    width: '150'
  }, {
    prop: 'whatsapp_number',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'phone_number',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'email',
    label: '',
    Object: 'value',
    width: '120'
  }]
}
